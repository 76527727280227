// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/author-page.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-catalog-download-tsx": () => import("./../../../src/templates/catalog-download.tsx" /* webpackChunkName: "component---src-templates-catalog-download-tsx" */),
  "component---src-templates-catalog-tsx": () => import("./../../../src/templates/catalog.tsx" /* webpackChunkName: "component---src-templates-catalog-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-private-label-tsx": () => import("./../../../src/templates/private-label.tsx" /* webpackChunkName: "component---src-templates-private-label-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-signup-tsx": () => import("./../../../src/templates/signup.tsx" /* webpackChunkName: "component---src-templates-signup-tsx" */)
}

