module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Verona Cosmetics","short_name":"Verona Cosmetics","start_url":"/","background_color":"#e8775c","theme_color":"#e8775c","display":"standalone","icon":"/var/www/veronacosmetics.com/releases/25/frontend/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"f3ef8cb356397a1bb7370706206029e7"},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"en","languages":["en","pl"],"siteUrl":"https://veronacosmetics.com","redirect":false,"pagination":{"translations":{"en":"page","pl":"strona"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-52740056-1","cookieName":"AP_ANALYTICS"},"hotjar":{"hjid":"2541614","hjsv":"6"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
